import { Spinner } from "@livingmap/core-ui-v2";

import styles from "./LoadingMapView.module.scss";

const LoadingMapView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <Spinner dataQA="loading-spinner" type="BeatLoader" />
      </div>
    </div>
  );
};

export default LoadingMapView;
